import { gql } from '@apollo/client';
import {
  benchmarkRequest,
  formComponentGroup,
  hirerProfileDetails,
  positionTemplateDetails,
  hirerCompanyRelationDetails,
  hirerPositionRelationDetails,
  statusType,
  draftAnswerGroup,
  submittedAnswerGroup,
  candidatePositionRelationDetails,
  candidateProfileDetails,
  SKILL_MAP_DETAILS,
  greenhousePartnerDetails,
  positionReferenceRequirements,
  referenceRequirementSyntax,
  hirerCompanyRoles,
  revieweeDetails,
} from './sharedApiConstants';
import { LEVER_PARTNERS_FRAGMENT, NOTIFICATION_TYPE_FRAGMENT } from './fragments';

export const ADD_HIRER_PROFILE = gql`
  mutation addHirerProfile($input: NewHirerProfileInput!){
    addHirerProfile(input: $input){
      ${hirerProfileDetails}
      hirerCompanyRelations {
        ${hirerCompanyRelationDetails}
      }
    }
  }
`;

export const INVITE_HIRER_PROFILE_TO_COMPANY = gql`
	mutation inviteHirerProfileToCompany($input: InviteHirerProfileToCompanyInput!){
		inviteHirerProfileToCompany(input: $input){
			${hirerProfileDetails}
			hirerCompanyRelations {
				${hirerCompanyRelationDetails}
      }
      hirerPositionRelations {
        hirerPositionRelationId
        positionId
      }
		}
	}
`;

export const ADD_HIRER_COMPANY_RELATION = gql`
  mutation addHirerCompanyRelation ($input: NewHirerCompanyRelationInput!) {
    addHirerCompanyRelation (input: $input) {
      ${hirerCompanyRelationDetails}
    }
  }
`;

export const UPDATE_HIRER_COMPANY_RELATION = gql`
  mutation updateHirerCompanyRelation ($input: UpdateHirerCompanyRelationInput!) {
    updateHirerCompanyRelation (input: $input) {
      ${hirerCompanyRelationDetails}
    }
  }
`;

const positionDetail = `
  totalCount
  page
  pageSize
  data {
    positionId
    positionTitle
    numCandidatePositionRelations
    companyId
    positionIdentifier
    numRequiredReferences
    referencerResponseDays
    createdAt
    isArchived
    positionTemplate {
      positionTemplateId
      templateName
    }
  }
`;

export const SEARCH_KEYWORD_PAGING_POSITIONS_FOR_HIRER = gql`
  query searchKeywordPagingPositionsForHirer($input: PositionKeywordPagingSearchForHirerInput!) {
    searchKeywordPagingPositionsForHirer(input: $input) {
      ${positionDetail}
    }
  }
`;

export const QUERY_POSITIONS_FOR_COMPANY_LIMITED = gql`
  query hirerPositionsForCompany($input: HirerPositionsForCompanyInput!) {
    hirerPositionsForCompany(input: $input) {
      positionId
      positionTitle
    }
  }
`;

export const FETCH_HIRER_POSITION_RELATIONS = gql`
  query fetchPositionForHirer($input: FetchPositionForHirerInput!) {
    fetchPositionForHirer(input: $input) {
      positionId
      hirerPositionRelations {	      
        hirerPositionRelationId
        receivesReferenceNotificationEmails
        hirerProfile {
          ${hirerProfileDetails}
        }
        benchmarkRequest {
          ${benchmarkRequest}
          hirerProfile {
            ${hirerProfileDetails}
          }
        }	
      }
    }
  }
`;

export const HIRER_COMPANY_RELATIONS_FOR_COMPANY = gql`
  query hirerCompanyRelationsForCompany($input: QueryHirerCompanyRelationPagingForCompanyInput!) {
    hirerCompanyRelationsForCompany(input: $input) {
      page
      pageSize
      totalCount
      data {
        hirerCompanyRelationId
        permissions
        hirerProfile {
          ${hirerProfileDetails}

          ${hirerCompanyRoles}
        }
      }
    }
  }
`;

export const SEARCH_HIRER_POSITION_RELATIONS = gql`
  query hirerPositionRelations($input: SearchHirerPositionRelationInput!) {
    hirerPositionRelations(input: $input) {
      ${hirerPositionRelationDetails}
    }
  }
`;

export const ADD_HIRER_POSITION_RELATION = gql`
  mutation addHirerPositionRelation($input: NewHirerPositionRelationInput!) {
    addHirerPositionRelation(input: $input) {
      ${hirerPositionRelationDetails}
    }
  }
`;

export const DELETE_HIRER_POSITION_RELATION = gql`
  mutation deleteHirerPositionRelation($input: SearchHirerPositionRelationInput!) {
    deleteHirerPositionRelation(input: $input) {
      hirerPositionRelationId
      positionId
      hirerProfileId
    }
  }
`;

export const UPDATE_BULK_HIRER_POSITION_RELATIONS = gql`
  mutation updateBulkHirerRelationsForPosition($input: UpdateBulkHirerRelationsForPositionInput!) {
    updateBulkHirerRelationsForPosition(input: $input) {
      ${hirerPositionRelationDetails}
    }
  }
`;

export const SUBMIT_SLIDING_SCALE_CUSTOMIZATION_REQUEST = gql`
  mutation submitSlidingScaleCustomizationRequest(
    $input: SubmitSlidingScaleCustomizationRequestInput!
  ) {
    submitSlidingScaleCustomizationRequest(input: $input) {
      success
    }
  }
`;

export const CREATE_AND_INVITE_BULK_CPRS = gql`
  mutation CreateAndInviteCandidatePositionRelations(
    $input: CreateAndInviteCandidatePositionRelationsInput!
  ) {
    createAndInviteCandidatePositionRelations(input: $input) {
      ${candidatePositionRelationDetails}
    }
  }
`;

export const KEYWORD_SEARCH_CANDIDATE_POSITION_RELATIONS = gql`
  query keywordSearchCandidatePositionRelations(
    $input: KeywordSearchCandidatePositionRelationsInput!
  ) {
    keywordSearchCandidatePositionRelations(input: $input) {
      totalCount
      page
      pageSize
      data {
        candidatePositionRelationId
        candidateEmail
        candidateFirstName
        candidateLastName
        candidateProfileId
        positionId
        ${statusType}
        candidateInvitedDate
        candidateProfile {
          candidateProfileId
          candidateEmail
          userId
          user {
            userId
            firstName
            lastName
            primaryEmail
          }
        }
        position {
          positionId
          positionTitle
          positionIdentifier
          companyId
          company {
            companyId
            companyName
            companyLogo
          }
        }
        greenhouseRequest {
          greenhouseRequestId
          ghJobs {
            name
          }
        }
        leverRequest {
          leverRequestId
          leverPostings {
            name
          }
        }
      }
    }
  }
`;

export const SEARCH_KEYWORD_PAGING_CRR = gql`
  query QueryCandidateReferencerRelationsForAdminHirer(
    $input: QueryCandidateReferencerRelationsForHirerInput!
  ) {
    queryCandidateReferencerRelationsForAdminHirer(input: $input) {
      page
      pageSize
      totalCount
      data {
        candidateReferencerRelationId
        candidateExperienceId
        candidateExperience {
          candidateExperienceId
          companyName
          companyLogo
          experienceSection {
            experienceSectionId
            candidateProfile {
              candidateProfileId
              user {
                userId
                firstName
                lastName
              }
            }
          }
        }
        referencerTitle
        timeOverlap
        workRelationship
        referencerEmail
        referencerFirstName
        referencerLastName
        isArchived
        contextForReference
        referencerProfile {
          referencerProfileId
          referencerEmail
          user {
            userId
            firstName
            lastName
          }
        }
      }
    }
  }
`;

export const UPDATE_HIRER_COMPANY_PERMISSIONS_FROM_ADMIN_HIRER = gql`
  mutation updateHirerCompanyPermissionsFromAdminHirer(
    $input: UpdateHirerCompanyPermissionsFromAdminHirerInput!
  ) {
    updateHirerCompanyPermissionsFromAdminHirer(input: $input) {
      permissionId
      permissionName
      hirerCompanyRelationId
    }
  }
`;

const excludedHirerApplicationDetailsLimited = `
  hirerExcludedApplicationId
  candidatePositionRelationId
  candidatePositionRelationLimited {
    candidatePositionRelationId
    candidateEmail
    candidateFirstName
    candidateLastName
    positionTitle
    companyId
  }
`;

export const FETCH_HIRER_PROFILES_WITH_COMPANY_RELATIONS = gql`
  query fetchHirerProfileForCompany ($input: FetchHirerProfileForCompanyInput!) {
    fetchHirerProfileForCompany (input: $input) {
      ${hirerProfileDetails}
      hirerCompanyRelations {
        hirerCompanyRelationId
        companyId
        permissions
      }
      ${hirerCompanyRoles}
      excludedCandidatePositionRelations {
        ${excludedHirerApplicationDetailsLimited}
      }
    }
  }
`;

export const DELETE_HIRER_COMPANY_RELATION_FOR_ADMIN_HIRER = gql`
  mutation deleteHirerCompanyRelationForAdminHirer($input: DeleteHirerCompanyRelationInput!) {
    deleteHirerCompanyRelationForAdminHirer(input: $input) {
      hirerCompanyRelationId
      hirerProfileId
      companyId
    }
  }
`;

export const UPDATE_HIRER_EXCLUDED_APPLICATIONS = gql`
  mutation updateHirerExcludedApplications($input: NewHirerExcludedApplicationsInput!) {
    updateHirerExcludedApplications(input: $input) {
      ${excludedHirerApplicationDetailsLimited}
    }
  }
`;

export const HIRER_SEARCH_CANDIDATE_POSITION_RELATIONS = gql`
  query candidatePositionRelationsForHirer($input: SearchCandidatePositionRelationsInput!) {
    candidatePositionRelationsForHirer(input: $input) {
      page
      pageSize
      totalCount
      sortField
      sortDirection
      keyword
      data {
        ${candidatePositionRelationDetails}
        candidateScore @client
        candidateProfile {
          ${candidateProfileDetails}
        }
        position {
          positionId
          positionTitle
          companyId
        }
      }
    }
  }
`;

export const HIRER_SEARCH_PARTICIPANT_POSITION_RELATIONS = gql`
  query candidatePositionRelationsForHirer($input: SearchCandidatePositionRelationsInput!) {
    candidatePositionRelationsForHirer(input: $input) {
      page
      pageSize
      totalCount
      sortField
      sortDirection
      keyword
      data {
        candidatePositionRelationId
        candidatePositionRelationKey
        opportunityId
        competencies_score
        numReferencesCompleted
        numReferencesInvited
        candidateEmail
        candidateInvitedDate
        ${statusType}
        candidateAssessment {
          candidateAssessmentId
          ${statusType}
        }
        reviewee {
          revieweeId

          managerHirerProfile {
            ${hirerProfileDetails}
          }
        }
        referenceStatus {
          statusTypeId
          typeName
        }
        reviewee {
          ${revieweeDetails}
        }
        newHireFit {
          scoreText
        }
        candidateScore @client
        candidateProfile {
          ${candidateProfileDetails}
        }
        pendingAndSubmittedReferenceRequests {
          submittedReferenceRequests{
            referenceRequestId
            checklistSubmission {
              percentageMarkedCompleted
              checklistAnswers {
                title
                subtitle
                status
                order
                questionComponentKey
              }
            }
          }
        }
        position {
          positionId
          positionTitle
          companyId
          reviewCycle {
            reviewCycleId
            name
          }
        }
      }
    }
  }
`;

export const QUERY_GH_PARTNER_FOR_COMPANY = gql`
  query SearchGreenhousePartners($input: GreenhousePartnerSearchInput!) {
    queryGreenhousePartnerForCompany(input: $input) {
      ${greenhousePartnerDetails}
    }
  }
`;

export const ADD_GH_PARTNER_FOR_COMPANY = gql`
  mutation addGreenhousePartner($input: NewGreenhousePartnerInput!) {
    addGreenhousePartner(input: $input) {
      ${greenhousePartnerDetails}
    }
  }
`;

export const UPDATE_GH_PARTNER_FOR_COMPANY = gql`
  mutation updateGreenhousePartner($input: UpdateGreenhousePartnerInput!) {
    updateGreenhousePartner(input: $input) {
      ${greenhousePartnerDetails}
    }
  }
`;

export const QUERY_LEVER_PARTNER = gql`
  query SearchLeverPartners($input: LeverPartnerSearchInput!) {
    leverPartners(input: $input) {
      ...leverPartnerDetails
    }
  }
  ${LEVER_PARTNERS_FRAGMENT}
`;

export const CREATE_LEVER_PARTNER = gql`
  mutation AddLeverPartner($input: NewLeverPartnerInput!) {
    addLeverPartner(input: $input) {
      leverPartnerId
      stageNameTrigger
      leverUserId
      leverApiKey
      signingToken
      webhookUrls
      defaultPositionTemplateId
    }
  }
`;

export const UPDATE_LEVER_PARTNER = gql`
  mutation UpdateLeverPartner($input: UpdateLeverPartnerInput!) {
    updateLeverPartner(input: $input) {
      leverPartnerId
      stageNameTrigger
      leverUserId
      leverApiKey
      signingToken
      webhookUrls
      defaultPositionTemplateId
    }
  }
`;

export const FETCH_ATTRIBUTES = gql`
  query attributes($input: SearchAttributesInput) {
    attributes(input: $input) {
      attributeId
      attributeName
      subtitle
      isStrength
    }
  }
`;

export const GENERATE_QUESTION_SUMMARY_REPORT_FOR_HIRER = gql`
  query generateQuestionSummaryReportForHirer($input: OpportunityPdfReportInput!) {
    generateQuestionSummaryReportForHirer(input: $input) {
      fileType
      base64Data
    }
  }
`;

export const GENERATE_REFERENCE_CENTRIC_SUMMARY_REPORT_FOR_HIRER = gql`
  query generateReferenceCentricReportForHirer($input: OpportunityPdfReportInput!) {
    generateReferenceCentricReportForHirer(input: $input) {
      fileType
      base64Data
    }
  }
`;

export const GENERATE_ONBOARDING_REPORT_FOR_HIRER = gql`
  query generateOnboardingReportForHirer($input: OpportunityPdfReportInput!) {
    generateOnboardingReportForHirer(input: $input) {
      fileType
      base64Data
    }
  }
`;

export const GENERATE_ASSESSMENT_REPORT_FOR_HIRER = gql`
  query generateAssessmentReportForHirer($input: OpportunityPdfReportInput!) {
    generateAssessmentReportForHirer(input: $input) {
      fileType
      base64Data
    }
  }
`;

export const FETCH_STATS_FOR_PEER_ASSESSMENT_BY_POSITION = gql`
  query GetStatsForPeerAssessment($input: Assessment360PositionDataInput!) {
    getStatsForPeerAssessment(input: $input) {
      peer_avg
      next_avg
      peer_target
      next_target
      skill_level_target
      next_level_title
      title
    }
  }
`;

export const UPDATE_PENDING_HIRER_INFORMATION = gql`
  mutation updatePendingHirerProfile($input: UpdateHirerProfileInput!) {
    updatePendingHirerProfile(input: $input) {
      ${hirerProfileDetails}
    }
  }
`;
/*
 * $SignInHirerWithBenchmarkInput: {
 *     uniqueCode
 * }
 */
export const SIGN_IN_HIRER_WITH_BENCHMARK = gql`
  mutation signInHirerWithBenchmark($input: SignInHirerWithBenchmarkInput!) {
    signInHirerWithBenchmark(input: $input) {
      benchmarkRequestId
      benchmarkRequestKey
      token
      refreshToken
      hirerProfile {
        ${hirerProfileDetails}
      }
    }
  }
`;
/*
 * $FetchBenchmarkRequestForHirer: {
 *     benchmarkRequestId
 * }
 */
export const FETCH_BENCHMARK_REQUEST_FOR_HIRER = gql`
  query fetchBenchmarkRequestForHirer( $input: FetchBenchmarkRequestForHirerInput! ) {
    fetchBenchmarkRequestForHirer (input: $input) {
      ${benchmarkRequest}
      draftAnswerGroup {
        ${draftAnswerGroup}
      }
      mostRecentlySubmittedAnswerGroup {
        ${submittedAnswerGroup}
      }
    }
  }
`;

/*
 * $BenchmarkRequestResponseInput {
 *    benchmarkRequestId: Int!
 *    answers: [ReferenceRequestNewAnswerInput {
 *        answerBody: JSON!
 *        subsectionQuestionRelationId: Int!
 *     }]
 *    currentSectionComponentRelationId: Int
 *  }
 */
export const SAVE_BENCHMARK_REQUEST = gql`
  mutation saveBenchmarkRequest($input: BenchmarkRequestResponseInput!) {
    saveBenchmarkRequest(input: $input) {
      benchmarkRequestId
      benchmarkRequestKey
      ${statusType}
      draftAnswerGroup {
        ${draftAnswerGroup}
      }
    }
  }
`;
/*
 * $BenchmarkRequestResponseInput {
 *    benchmarkRequestId: Int!
 *    answers: [ReferenceRequestNewAnswerInput {
 *        answerBody: JSON!
 *        subsectionQuestionRelationId: Int!
 *     }]
 *    currentSectionComponentRelationId: Int
 *  }
 */
export const SUBMIT_BENCHMARK_RESPONSE = gql`
  mutation SubmitBenchmarkRequest($input: BenchmarkRequestResponseInput!) {
    submitBenchmarkRequest(input: $input) {
      benchmarkRequestId
      benchmarkRequestKey
      ${statusType}
      draftAnswerGroup {
        answerGroupId
        answerGroupKey
      }
      mostRecentlySubmittedAnswerGroup {
        ${submittedAnswerGroup}
      }
    }
  }
`;
/*
 * $getOrCreateBenchmarkAndBenchmarkRequests: {
 *     positionId: Int!
 *     hirerProfileIdsForBenchmarkRequests: [Int]
 * }
 */
export const GET_OR_CREATE_BENCHMARK_AND_BENCHMARK_REQUESTS = gql`
  mutation GetOrCreateBenchmarkAndCreateBenchmarkRequests(
    $input: getOrCreateBenchmarkAndCreateBenchmarkRequestsInput!
  ) {
    getOrCreateBenchmarkAndCreateBenchmarkRequests(input: $input) {
      ${benchmarkRequest}
    }
  }
`;

export const FETCH_POSITION_FOR_HIRER = gql`
  query fetchPositionForHirer($input: FetchPositionForHirerInput!) {
    fetchPositionForHirer(input: $input) {
      positionId
      positionKey
      positionTitle
      positionIdentifier
      referencerResponseDays
      validSurveySubmissionWindowDays
      externalJobId
      externalJobSourceId
      numRequiredReferences
      candidateViewFullReport
      reviewCycleId
      isAssessment360Position
      usesExcitementQuestion
      usesChecklistQuestion
      usesNewHireFit
      usesCompetencyQuestions
      isArchived
      notifyCandidateWithUpdates
      subjectCanInviteRespondent
      sendAutomaticRemindersToSubjects
      hideRespondentName

      benchmarkPositionRelations {
        benchmarkPositionRelationsId
        benchmarkId
        benchmark {
          benchmarkId
          benchmarkTitle
          benchmarkKey
        }
      }

      referenceRequirementSyntax {
        ${referenceRequirementSyntax}
      }

      candidateFormComponentGroup {
        formComponentGroupId
        formComponentGroupKey
        componentText
      }

      formComponentGroup {
        formComponentGroupKey
        formComponentGroupId
        componentText
      }

      positionTemplate {
        positionTemplateId
      }

      positionSkillMapRelations {
        positionSkillMapRelationId
        skillMapId
        targetSkillMapLevelIds

        skillMap {
          ${SKILL_MAP_DETAILS}
        }
      }

      reviewCycle {
        reviewCycleId
        reviewCycleKey
        name
      }

      ${positionReferenceRequirements}

      surveysToRelationships {
        positionSurveyToRelationshipKey
        formComponentGroupKey
        formComponentGroup {
          componentText
          formComponentGroupKey
          formComponentGroupId
        }
        referenceRelationTypeKey
        referenceRelationType {
          typeName
          referenceRelationTypeKey
          referenceRelationTypeId
        }
      }
    }
  }
`;

export const FETCH_FORM_FOR_HIRER = gql`
  query fetchFormForHirer($input: FetchFormForHirerInput!) {
    fetchFormForHirer(input: $input) {
      ${formComponentGroup}
    }
  }
`;

export const RESEND_BENCHMARK_REQUEST = gql`
  mutation ResendBenchmarkRequest($input: ResendBenchmarkRequestInput!) {
    resendBenchmarkRequest(input: $input) {
      benchmarkRequestId
      benchmarkRequestKey
      submissionDate
      ${statusType}
      mostRecentlySubmittedAnswerGroup {
        answerGroupId
      }
    }
  }
`;

export const DELETE_BENCHMARK_REQUEST = gql`
  mutation DeleteBenchmarkRequest($input: DeleteBenchmarkRequestInput!) {
    deleteBenchmarkRequest(input: $input)
  }
`;

export const MERGE_POSITIONS = gql`
  mutation MergePositions($input: MergePositionsInput!) {
    mergePositions(input: $input) {
      positionIdToArchive
      positionIdToMergeTo
    }
  }
`;

export const DUPLICATE_POSITION = gql`
  mutation DuplicatePosition($input: DuplicatePositionInput!) {
    duplicatePosition(input: $input) {
      positionId
      positionTitle
      companyId
      formComponentGroupId
      candidateInviteFromHirer
      referencerInviteFromHirer
      numRequiredReferences
      referencerResponseDays
      details
    }
  }
`;

export const MOVE_CANDIDATE_TO_POSITION = gql`
  mutation MoveCandidateToPosition($input: MoveCandidateToPositionInput!) {
    moveCandidateToPosition(input: $input) {
      candidatePositionRelationId
      positionId
    }
  }
`;

export const GET_EXTERNAL_JOB_SOURCES_FOR_COMPANY = gql`
  query externalJobSourcesForCompany($input: ExternalJobSourcesForCompanyInput!) {
    externalJobSourcesForCompany(input: $input) {
      externalJobSourceId
      sourceName
    }
  }
`;

export const ADD_POSITION_TEMPLATE_FOR_COMPANY = gql`
  mutation addPositionTemplateForCompany($input: AddPositionTemplateForCompanyInput!) {
    addPositionTemplateForCompany(input: $input) {
      ${positionTemplateDetails}
    }
  }
`;

export const QUERY_POSITION_TEMPLATES_FOR_COMPANY_PAGING = gql`
  query positionTemplatesForCompanyPaging($input: PositionTemplatesForCompanyPagingInput!) {
    positionTemplatesForCompanyPaging(input: $input) {
      pageSize
      page
      totalCount
      data {
        ${positionTemplateDetails}
      }
    }
  }
`;

export const GET_POSITION_TEMPLATE_BY_ID = gql`
  query positionTemplateById($input: PositionTemplateByIdInput!) {
    positionTemplateById(input: $input) {
      ${positionTemplateDetails}
    }
  }
`;

export const UPDATE_POSITION_TEMPLATE = gql`
  mutation updatePositionTemplate($input: UpdatePositionTemplate!) {
    updatePositionTemplate(input: $input) {
      ${positionTemplateDetails}
    }
  }
`;

export const DELETE_POSITION_TEMPLATE = gql`
  mutation deletePositionTemplate($input: DeletePositionTemplateInput!) {
    deletePositionTemplate(input: $input) {
      ${positionTemplateDetails}
    }
  }
`;

export const ADD_SLACK_NOTIFICATION = gql`
  mutation AddSlackNotification($input: AddSlackNotificationInput!) {
    addSlackNotification(input: $input) {
      companyId
      slackEnabled
    }
  }
`;

export const REMOVE_SLACK_FOR_COMPANY = gql`
  mutation RemoveSlackForCompany($input: Int!) {
    removeSlackForCompany(input: $input) {
      companyId
      slackEnabled
      notificationTypeId
    }
  }
`;

export const UPDATE_COMPANY_NOTIFICATION_EVENTS = gql`
  mutation UpdateCompanyNotificationEvents($input: UpdateCompanyNotificationEventsInput!) {
    updateCompanyNotificationEvents(input: $input) {
      companyId
      notificationEvents {
        notificationEventId
        notificationEventName
      }
    }
  }
`;

export const UPDATE_COMPANY_NOTIFICATION_TYPE = gql`
  mutation UpdateCompanyNotificationType($input: UpdateCompanyNotificationTypeInput!) {
    updateCompanyNotificationType(input: $input) {
      companyId
      notificationTypeId
    }
  }
`;

export const FETCH_NOTIFICATION_TYPES = gql`
  query NotificationTypes {
    notificationTypes {
      ...notificationTypeDetails
    }
  }
  ${NOTIFICATION_TYPE_FRAGMENT}
`;

export const CREATE_AND_INVITE_REVIEWEES_AND_REVIEWERS = gql`
  mutation createAndInviteRevieweesAndReviewers(
    $input: CreateAndInviteRevieweesAndReviewersInput!
  ) {
    createAndInviteRevieweesAndReviewers(input: $input) {
      candidatePositionRelationId
    }
  }
`;

export const RETRY_INVITATION = gql`
  mutation RetryInvitationForCandidatePositionRelation(
    $input: RetryInvitationForCandidatePositionRelationInput!
  ) {
    retryInvitationForCandidatePositionRelation(input: $input) {
      candidatePositionRelationId
      ${statusType}
    }
  }
`;
